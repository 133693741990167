import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";
import { http } from "../../utils/api";
import { debounce } from "../../utils/debounce";
import { Deferred } from "../../utils/deferred";
import { jsonapi } from "../../utils/jsonapi";
import { pickStorage } from "../pick_storage";
import sample_data, { choice } from "./sample_data";
import { TsunamiUser } from "./user_defs";

export const useUserStore = defineStore("users", () => {
  const current = ref(newUser() as TsunamiUser);
  const list = ref<TsunamiUser[]>([]);
  // const store = localforage.createInstance({ name: PREFIX + ":user" });
  const store = pickStorage("user");
  const userId = ref("");

  let loggingIn: Deferred | null = null;
  const ready = new Deferred();

  watch(
    () => current.value?.id,
    async () => {
      localStorage.user = current.value.id;

      if (current.value?.id) {
        if (!current.value.hats) {
          current.value.hats = [];
        }

        const response = await http({ event: "user" });
        for (const key of ["hats", "space", "space_used"]) {
          current.value[key] = response[key];
        }
      }
    }
  );

  watch(
    () => current.value?.updated,
    (o, n) => {
      if (current.value?.id) {
        const record = JSON.parse(JSON.stringify(current.value));
        record.updated = new Date().toISOString();
        store.setItem(current.value.id, record);
        if (userId.value === current.value.id) {
          console.warn("saving user", o, n);
        } else {
          console.log("user switched");
        }
        userId.value = current.value.id;
      }
    },
    { deep: true }
  );

  const isSuper = computed(() => {
    return current.value.hats?.includes("super");
  });
  const isEditor = computed(() => {
    return isSuper.value || current.value.hats?.includes("editor");
  });

  async function initialize(force = false) {
    try {
      console.warn("initializing users", force, ready.resolved);
      if (force || !ready.resolved) {
        const userId = sessionStorage.user ?? localStorage.user;
        console.log("current user?", userId);
        if (userId) {
          const user = await store.getItem(userId, undefined);
          if (user) {
            current.value = user as TsunamiUser;
          }
        }

        // get user from localstorage
        for (const key of ["cyoa:user"]) {
          const localUserSource = localStorage.getItem(key);
          if (localUserSource) {
            try {
              console.log("- user from single localstorage", key, localUserSource);
              const localUser = JSON.parse(localUserSource);
              localUser.dbSource = `localStorage.${key}`;
              if (localUser?.id && !current.value?.id) {
                current.value = localUser;
              }
            } catch (err) {}
          }
        }

        // get users from localstorage
        for (const key of ["_users"]) {
          const localUsersSource = localStorage.getItem(key);
          if (localUsersSource) {
            try {
              console.log("- user from multi localstorage", key, localUsersSource);
              const localUsers = JSON.parse(localUsersSource);
              if (localUsers?.length > 0) {
                for (const row of localUsers) {
                  row.dbSource = `localStorage.${key}`;
                  list.value.push(row);
                  if (row.id && !current.value?.id) {
                    current.value = row;
                  }
                }
              }
            } catch (ex) {}
          }
        }

        // get first user in the backup dbs
        const stores = {
          primary: store,
        };
        for (const key in stores) {
          const db = stores[key];
          await db.iterate((row: TsunamiUser) => {
            if (row) {
              console.log("- user from localforage", key, row);
              row.dbSource = key;
              list.value.push(row);
              if (!current.value?.id) {
                current.value = row;
              }
            }
          });
        }

        if (!current.value.username) {
          current.value.username = generateUsername();
          current.value.updated = new Date().toISOString();
        }

        console.log("current", current.value, ready.resolve);
        if (ready.resolve) {
          ready.resolve(true);
        }

        renewLogin();
      }
    } catch (e) {
      console.log("user initialize error", e);
      ready.resolve(false);
    }
  }

  function newUser(): TsunamiUser {
    return {
      id: undefined,
      username: generateUsername(),
    };
  }

  function generateUsername(): string {
    return `${choice(sample_data.descriptions)}-${choice(sample_data.names)}`;
  }

  async function login() {
    console.log("attempting to login");
    await ready.promise;
    if (!current.value.token) {
      if (!loggingIn) {
        if (await debounce("user-login", 10)) {
          loggingIn = new Deferred();
          try {
            await Promise.race([
              httpLogin(),
              new Promise((r, err) => setTimeout(() => err(new Error("login timeout")), 15000)),
            ]);
          } finally {
            if (loggingIn.resolve) {
              loggingIn.resolve();
              loggingIn = null;
            }
          }
        }
      } else {
        await loggingIn.promise;
      }
    } else {
      await renewLogin();
    }
  }

  async function renewLogin(force: boolean = false) {
    if (current.value.id && current.value.token && (force || (await debounce("renewLogin")))) {
      let shouldRenew = force;

      if (!shouldRenew && current.value.tokenCreated) {
        const cutoff = new Date(Date.now() - 1000 * 60 * 60 * 24 * 14);
        // console.log("refresh token?", new Date(current.value.tokenCreated), cutoff);
        shouldRenew = new Date(current.value.tokenCreated) < cutoff;
      }
      if (!shouldRenew && current.value.tokenExpires) {
        const cutoff = new Date(Date.now() + 1000 * 60 * 60 * 24 * 30);
        // console.log("refresh token? (exp)", new Date(current.value.tokenExpires), cutoff);
        shouldRenew = new Date(current.value.tokenExpires) < cutoff;
      }
      if (shouldRenew) {
        console.log("refreshing token...");
        const tokenRefresh = await jsonapi({
          event: "rest/tokens/refresh",
          headers: { "X-UID": current.value.id, AUTHORIZATION: `Token ${current.value.token}` },
          method: "POST",
        });
        console.log("token refresh", current.value.id, tokenRefresh);
        if (!tokenRefresh.error && !tokenRefresh.detail) {
          const prevToken = current.value.token;
          const echoTest = await jsonapi({
            event: "rest/users",
            headers: { "X-UID": current.value.id, AUTHORIZATION: `Token ${tokenRefresh.token}` },
            method: "get",
          });
          if (echoTest.uid === current.value.id) {
            current.value.token = tokenRefresh.token as string;
            current.value.tokenExpires = tokenRefresh.expires;
            current.value.tokenCreated = tokenRefresh.created;
            current.value.updated = new Date().toISOString();

            // delete old token
            const id = prevToken.split(":")[0];
            await jsonapi({
              event: `rest/tokens/${id}`,
              headers: {
                "X-UID": current.value.id,
                AUTHORIZATION: `Token ${current.value.token}`,
              },
              method: "DELETE",
            });
          } else {
            console.error("refresh echo test failed", echoTest);
          }
        } else {
          console.error("refresh failed", tokenRefresh);
        }
      }
      if (!current.value.token) {
        await resetLogin();
        await httpLogin();
      }
    }
  }

  async function httpLogin() {
    if (current.value.tokenExpires) {
      if (new Date(current.value.tokenExpires) < new Date()) {
        console.error(
          "token expired, doing last ditch effort for token:",
          current.value.token,
          "expiring:",
          current.value.tokenExpires
        );
        await renewLogin();
      }
    }
    if (!current.value.token && current.value.id && current.value.pub) {
      const response = await jsonapi({
        event: "rest/users/login",
        data: { uid: current.value.id, key: current.value.pub },
        method: "POST",
      });
      if (!response.error) {
        current.value.token = response.token;
        current.value.tokenExpires = response.expires;
        current.value.updated = new Date().toISOString();
        // TODO: later when confident that everything is working
        // await removePublicKey();
      } else {
        resetLogin();
      }
    }
    if (!current.value.token && !current.value.id) {
      const response = await jsonapi({
        event: "rest/users/register",
        data: { username: current.value.username },
        method: "POST",
      });
      current.value.id = response.user.uid;
      current.value.created = response.user.created;
      current.value.updated = response.user.updated;
      current.value.token = response.token.token;
      current.value.tokenExpires = response.token.expires;
      current.value.tokenCreated = response.token.created;
      current.value.pub = undefined;
      current.value.updated = new Date().toISOString();
    }
  }

  async function removePublicKey() {
    if (current.value.token && current.value.id && current.value.pub) {
      const response = await jsonapi({
        event: "rest/users/remove-public-key",
        data: { remove: [current.value.pub] },
        method: "POST",
      });
    }
  }

  function resetLogin() {
    current.value.id = undefined;
    current.value.token = undefined;
    current.value.tokenCreated = undefined;
    current.value.tokenExpires = undefined;
    current.value.updated = new Date().toISOString();
  }

  async function remove(user: TsunamiUser) {
    if (user.id) {
      await store.removeItem(user.id);
    }
    const index = list.value.indexOf(user);
    list.value.splice(index, 1);
  }

  initialize();

  return {
    current,
    isSuper,
    isEditor,
    list,
    ready: ready.promise,
    readyDeferred: ready,
    store,
    initialize,
    login,
    renewLogin,
    resetLogin,
    remove,
    generateUsername,
  };
});
