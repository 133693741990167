<template>
  <Select
    v-bind="$attrs"
    v-model="value"
    :invalid="errors.length > 0"
    optionLabel="label"
    optionValue="value"
  />
</template>

<script setup lang="ts">
import Select from "primevue/select";
import { useField } from "vee-validate";

const props = withDefaults(
  defineProps<{
    name: string;
    showTitle?: boolean;
    accept?: string;
  }>(),
  { accept: "image" }
);

const { value, errors } = useField<any>(props.name);
</script>

<style scoped></style>
