<template>
  <searching-modal
    :config="{
      keys: ['title'],
      includeMatches: true,
      findAllMatches: true,
    }"
    :placeholder="$t('tutorial_popup.search-tutorials')"
    :options="tutorials.stories.filter((t) => !t.system)"
    :value="data.typing"
    v-model:filter="data.typing"
    @show="$emit('show', $event)"
    @update:value="handleInput"
    @action="handleInput"
  >
    <template v-slot:default="{ result }">
      <span class="block result">
        <b>{{ result.item.title }}</b>
      </span>
    </template>
  </searching-modal>
</template>

<script lang="ts" setup>
import { useTutorialStore } from "@/stores/tutorials";
import { nextTick, onMounted, reactive, watch } from "vue";
import SearchingModal from "./searching_modal.vue";

const props = withDefaults(
  defineProps<{
    target?: string;
    story?: BaseStory;
    value?: string;
    type?: string;
  }>(),
  { type: "all" },
);
const emits = defineEmits<{
  "update:value": [string | null];
  show: [boolean];
}>();

const tutorials = useTutorialStore();

const data = reactive({
  status: null,
  results: null,
  typing: props.value,
  error: null,
});

// const match = computed(() => {
//   const terms = (data.typing || "").split(/ |\//g);
//   let results = this.tags.slice();
//   for (const term of terms) {
//     results = results.filter((r) => r.category === term || r.name === term);
//   }
//   if (results.length > 0) {
//     return results[0];
//   }
// });

watch(
  () => props.value,
  () => {
    data.typing = props.value;
  },
);

onMounted(async () => {
  // auto select
  await nextTick();
});

async function handleInput(item) {
  emits("update:value", item ? item.id : null);
}
function handleChoice(item) {
  emits("update:value", item.id);
  emits("show", false);
}
</script>

<style scoped>
#app .close-button {
  display: inline-block;
  flex: 0 0 auto;
  margin: 0;
  font-size: 25px;
  height: 30px;
  width: 30px;
  padding: 0;
  place-self: flex-end;
  align-self: flex-start;
  text-align: right;
  margin: 0;
}
a {
  padding: 0;
}
.result.row {
  padding: 5px;
  margin: 3px;
}
.search {
  max-width: 100%;
}
.result {
  margin: 0;
  padding: 6px;
}
.category {
  flex: 0 1 auto;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 10px;
}
.top {
  align-self: flex-start;
}
.label {
  background: var(--color2);
  color: var(--color2_text);
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
}
.title,
.content {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.icon {
  flex: 0 0 auto;
  padding: 5px;
  margin-right: 9px;
  font-size: 21px;
}
.missing .block {
  padding: 6px;
}
.text-header {
  margin-bottom: 5px;
}
.formula-input {
  min-width: max-content;
  min-height: max-content;
}
</style>
