import { Character, Story } from "@/stores/story_defs";

const april: Character = {
  id: "april",
  name: "April",
  width: 120,
  emotes: [
    {
      id: "default",
      name: "fox",
      label: "April",
      // aura: "transparent",
      // position: "left",
      permalink:
        "https://tsunami.darkchocolate.dev/static/cyoa/assets/tutorials/malpractice1/fox.png",
    },
  ],
  bio: "The #1 Sales fox",
};
const styles = [
  {
    id: "default",
    name: "default",
    default: true,
    foreground: "rgba(0, 0, 0, 1)",
    shadow: "rgba(255, 255, 255, 1)",
    shadowLength: "5px",
    background: "rgba(255, 255, 255, 0.8)",
    background2: "rgba(255, 255, 255, 0.8)",
    gradient: null,
    font: "literata_bookregular",
    size: "1.0em",
    particles: {},
    particlesEnabled: false,
  },
  {
    id: "labels",
    name: "Character Label",
    default: false,
    foreground: "var(--spark_text)",
    background: "var(--spark)",
    background2: "rgba(255, 255, 255, 0.8)",
    gradient: null,
    font: "literata_bookregular",
    size: null,
    particles: {},
    particlesEnabled: false,
    animation: {
      enter: null,
      exit: null,
    },
  },
];

export function createTutorial(config): Story {
  const story: Story = {
    id: config.title.toLowerCase().replace(/ /, "-"),
    title: config.title,
    description: config.description,
    settings: {
      background: "transparent",
      textLabelStyle: "labels",
    },
    characters: [april],
    styles,
    tags: [
      {
        id: "hasTestStory",
        name: "hasTestStory",
      },
      {
        id: "tutorialCount",
        name: "tutorialCount",
      },
    ].concat(config.tags || []),
    scenes: config.scenes,
    system: config.system || false,
  };

  story.scenes.forEach((scene, sid) => {
    scene.id = scene.id || sid + 1;
    scene.directions.forEach((direction, id) => {
      direction.id = id + 1;
      if (!direction.type) {
        direction.type = "text";
      }
      if (direction.type === "text") {
        if (!direction.character) {
          direction.character = "april";
        }
        if (!direction.state) {
          direction.state = "default";
        }
      }
    });
  });

  return story;
}

export function createEffects(conditions) {
  let effects = undefined;
  if (conditions) {
    effects = [];
    for (let key of Object.keys(conditions)) {
      if (conditions[key]) {
        effects.push({
          tag: key,
          op: "#",
        });
      } else {
        effects.push({
          tag: key,
          op: "!",
        });
      }
    }
  }
  return effects;
}

export function text(content, conditions?) {
  return {
    type: "text",
    content,
    effects: createEffects(conditions),
  };
}

export function automation(actions, conditions) {
  return {
    type: "event",
    name: "automate",
    content: actions.concat([{ action: "pause", args: [300] }]),
    effects: createEffects(conditions),
  };
}

export const createTestStory = {
  action: "patch",
  args: [
    {
      scenes: [{ id: "1", directions: [{ id: "1", type: "text", content: "" }] }],
      start: true,
    },
  ],
};
export const createEmptyTestStory = {
  action: "patch",
  args: [
    {
      scenes: [{ id: "1", directions: [] }],
      start: true,
    },
  ],
};
export const navigateToTestStory = { action: "navigate", args: ["/story/test/scene/1/"] };
export const navigateToStories = { action: "navigate", args: ["/story/refresh/"] };
