<template>
  <div
    class="background-image"
    appear
    @pointermove="handlePan"
    @pointerdown="handlePan"
    @pointercancel="handlePan"
    @pointerup="handlePan"
    @wheel.prevent="handlePan"
    @click.prevent="handleClick"
  >
    <transition-group appear name="fade-slow">
      <!-- <transition appear name="fade-slow" v-for="bg of backgrounds" :key="`bg-${bg.blob_id}`"> -->
      <div
        v-for="bg of backgrounds"
        :key="`bg-${bg.blob_id}`"
        :class="{ bg: true, repeat: bg.repeat }"
        :style="{
          'background-color': bg.color ? bg.color : '',
          'background-image': bg.url ? `url(${bg.url})` : '',
          'background-repeat': bg.repeat ? 'repeat' : 'no-repeat',
          'background-size':
            bg.size === 'explore'
              ? `${size.x * xy.scale}px ${size.y * xy.scale}px`
              : bg.size || 'cover',
          'background-position': bg.size === 'explore' ? `calc(${xy.x}px) calc(${xy.y}px)` : '',
          'transition-duration': `${story.settings.bgSpeed || 3 + 0.001}s !important`,
        }"
      >
        <span style="color: white"></span>
      </div>
      <!-- </transition> -->
    </transition-group>
    <div
      v-for="glyph of glyphs"
      :key="`glyph-${glyph.id}`"
      class="glyph-dot"
      :class="{ 'glyph-hidden': glyph.hidden }"
      :style="{
        transform: `
          translate(${glyph.x * xy.scale + xy.x}px,
                    ${glyph.y * xy.scale + xy.y}px)
          scale(${xy.scale / glyph.size})
        `,
        zIndex: 4,
      }"
      @click="$emit('glyph-click', glyph.choice)"
      :title="`glyph-${glyph.id}`"
    ></div>
  </div>
</template>

<script>
import Media from "../../models/media";
import Explore from "../../utils/explore";

export default {
  props: {
    story: Object,
    backgrounds: Array,
    glyphs: {
      type: Array,
      default() {
        return [];
      },
    },
    exploring: { type: Boolean, default: false },
  },
  data() {
    return {
      explore: new Explore(),
      start: {},
      end: {},
      images: [],
    };
  },
  computed: {
    zoom() {
      return this.backgrounds.reduce((a, b) => Math.max(a, b.zoom), 0) || 2;
    },
    zoomMin() {
      return this.backgrounds.reduce((a, b) => Math.max(a, b.zoomMin), 0) || 0;
    },
    zoomMax() {
      return this.backgrounds.reduce((a, b) => Math.max(a, b.zoomMax), 0) || 4;
    },
    xy() {
      return this.explore;
    },
    size() {
      return this.images
        .filter((i) => i)
        .reduce(
          (a, b) => {
            return {
              x: Math.max(a.x, b.width),
              y: Math.max(a.y, b.height),
            };
          },
          { x: 0, y: 0 },
        );
    },
    isExplore() {
      return this.backgrounds.reduce((a, b) => {
        return a || b.size === "explore";
      }, false);
    },
  },
  watch: {
    backgrounds: {
      handler() {
        this.getSizes();
      },
      deep: true,
    },
    isExplore() {
      this.$emit("update:exploring", this.isExplore);
    },
  },
  async mounted() {
    await this.getSizes();
    if (window.ResizeObserver) {
      this._resizer = new window.ResizeObserver(() => {
        this.getSizes();
      });
      this._resizer.observe(this.$el);
    }
  },
  async unmounted() {
    if (this._resizer) {
      this._resizer.disconnect();
    }
  },
  methods: {
    async getSizes() {
      this.images = await Promise.all(
        this.backgrounds.map((b) => {
          return Media.load(b.blob_id);
        }),
      );
      this.explore = new Explore({
        width: this.size.x,
        height: this.size.y,
        scale: this.zoom,
      });
      await new Promise((r) => setTimeout(r, 100));
      this.explore.changeContainer(null);
      this.explore.minScale = Math.max(this.explore.minScale, this.zoomMin);
      this.explore.maxScale = this.zoomMax;
    },
    handleClick(e) {
      const refs = document
        .elementsFromPoint(e.x, e.y)
        .filter((el) => el.classList.contains("glyph-dot"));
      if (refs.length > 0) {
        refs[0].click();
      }
    },
    handlePan(e) {
      if (this.isExplore) {
        this.explore.handle(e);
      }
    },
  },
};
</script>

<style scoped>
.background-image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  justify-content: stretch;
  align-items: stretch;
  display: grid;
}
.background-image > .bg {
  transform: translate3d(0, 0, 0);
  grid-row: 1 / 1;
  grid-column: 1 / 1;
  /* background-size: cover; */
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.background-image .repeat {
  background-size: auto;
  background-repeat: repeat;
}
#app .fade-enter-active {
  transition: opacity 500ms;
}
#app .fade-leave-active {
  transition: opacity 0ms;
}
#app .fade-enter-from {
  opacity: 0;
}
#app .fade-leave-to {
  opacity: 1;
}
.fade-slow-enter-active,
.fade-slow-leave-active {
  transition: opacity 3000ms;
}
#app .fade-slow-enter-from {
  opacity: 0;
}
#app .fade-leave-to {
  opacity: 1;
}
.label {
  color: purple;
  width: auto;
  height: min-content;
}
.glyph-dot {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  margin-left: -50px;
  margin-top: -50px;
  border-radius: 100%;
  border: 5px solid var(--spark);
  background: var(--spark_50);
  opacity: 0.8;
}
.glyph-dot.glyph-hidden {
  opacity: 0;
}
</style>
