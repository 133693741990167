import { i18n } from "@/i18n";
import { Story } from "@/stores/story_defs";
import {
  automation,
  createEmptyTestStory,
  createTestStory,
  createTutorial,
  navigateToStories,
  navigateToTestStory,
  text,
} from "./tools";
import multiplayer from "./tutorials/multiplayer";

// import tagsAndActions from './tagsAndActions.json';

export default function (): Story[] {
  return [
    createTutorial({
      title: "Writing Overview",
      scenes: [
        {
          directions: [
            text("Hello!\n" + "I'm April, and I'll be helping you through these tutorials"),
            automation([navigateToStories]),
            text("This is the home page. You can view your stories here."),
            text(
              "I'm going to create a test story for this tutorial...\n" +
                "Don't use this story for actual writing, it could be erased by other tutorials",
              { hasTestStory: false },
            ),
            automation([createTestStory, navigateToStories], { hasTestStory: false }),
            automation([createTestStory], { hasTestStory: true }),
            text("Let's click on the test story"),
            automation([{ action: "click", args: [".story-test .title a"] }]),
            text(
              "This is the story dashboard. I'm not going to go over all of this right, now.\n" +
                "Instead, lets get writing",
            ),
            automation([{ action: "click", args: [".scenes .header a"] }]),
            text(
              "This is the Scene Editor\n" +
                "Here you can write a section of your story\n\n" +
                "Let me point out the most important buttons",
            ),
            automation([{ action: "goto", args: [".read-story-link"] }]),
            text(
              "This eye will start open your story as a reader\n" +
                "The reader will won't start over each time you start it, " +
                "it was designed to remember where you were last reading",
            ),
            automation([{ action: "goto", args: [".overview-link"] }]),
            text("This globe will show you an overview of your scenes and how they link together"),
            automation([{ action: "goto", args: [".open-tutorials"] }]),
            text("And this question mark will let you open up other tutorials"),
            text(
              "Finally, let's start writing\n" +
                "A scene usually starts out with a text area for writing\n" +
                "But you can add a text with the menu below",
            ),
            automation([
              { action: "goto", args: ["textarea"] },
              {
                action: "type",
                args: ['The Evil Overlord smiled. "There is nothing you can do!"'],
              },
            ]),
            text(
              "Typically a scene will have at least one text and end with choices, " +
                "So let's add a few of those",
            ),
            automation([
              { action: "click", args: [".direction-type-choice"] },
              { action: "goto", args: [".direction:nth-child(2) textarea"] },
              { action: "type", args: ["You haven't seen my final form!"] },
              { action: "click", args: [".direction-type-choice"] },
              { action: "goto", args: [".direction:nth-child(3) textarea"] },
              { action: "type", args: ["Fine I give up"] },
            ]),
            text(
              "Choices need to be linked to other scenes, but we'll do that in another tutorial\n" +
                "This story won't be very exciting, but it's a start. Let's read!",
            ),
            automation([
              { action: "click", args: [".read-story-link"] },
              { action: "pause", args: [2000] },
            ]),
            text(
              "Enjoy the story! Hopefully that helps you get started\n" +
                "Don't forget that the test story will be erased by other tutorials.\n" +
                "Create a new story for your own work",
            ),
          ],
        },
      ],
    }),
    createTutorial({
      title: "Scene Editor",
      scenes: [
        {
          directions: [
            text("Hello, let's go over the `scene editor`"),
            text("I need to create a test story first...", { hasTestStory: false }),
            automation([createTestStory, navigateToStories], { hasTestStory: false }),
            text("Alright, now I'm going to navigate to our test story"),
            automation([navigateToTestStory]),
            text("Here we are! This is the `scene editor`"),
            text("Let me point out some of the buttons"),
            automation([{ action: "click", args: ["input.scene-title"] }]),
            text("This is the scene header"),
            automation([{ action: "click", args: [".button-bar"] }]),
            text("This is the scene toolbar"),
            automation([{ action: "click", args: [".insert-direction"] }]),
            text("You can add a direction with the buttons here"),
          ],
        },
      ],
    }),
    createTutorial({
      title: "Backgrounds",
      scenes: [
        {
          directions: [
            text("Backgrounds add more visual interest to your story. Let's add one!"),
            automation([createEmptyTestStory, navigateToStories]),
            automation([navigateToTestStory]),
            text("First, we will add a background direction"),
            automation([{ action: "click", args: [".direction-type-background"] }]),
            text("Since there are no backgrounds to choose, we shall create one"),
            automation([{ action: "click", args: [".create-background"] }]),
            text("I'll upload a background that I made just for you"),
            automation([
              { action: "click", args: [".fa-plus"] },
              { action: "click", args: ["[name=name]"] },
              { action: "type", args: ["Awesome Background"] },
              { action: "click", args: [".url-button"] },
              { action: "click", args: ["[type=url]"] },
              {
                action: "type",
                args: [
                  "https://tsunami.darkchocolate.dev/static/cyoa/assets/Backgrounds/heavenly_clouds.png",
                ],
              },
              { action: "click", args: [".confirm-url"] },
            ]),
            text(
              "Normally, you would click the `upload` button to add an image and choose one from your device.",
            ),
            text(
              "However, if you use a cloud file service, you can sometimes get a URL and use a background from your cloud",
            ),
            text(
              "Using a cloud file makes the story size smaller and easier for others to download",
            ),
            text("Let's go back and use our new background"),
            automation([navigateToTestStory]),
            text("Now we can choose the background to use"),
            automation([
              { action: "click", args: [".choose-background"] },
              { action: "click", args: [".frame"] },
              { action: "click", args: [".direction-type-text"] },
            ]),
            text("Sorry, I need to add some text to put this image in context"),
            automation([
              { action: "goto", args: ["textarea"] },
              {
                action: "type",
                args: ["Outside the sky was bright, shining with heavenly light"],
              },
            ]),
            text("Awesome! We read!"),
            automation([{ action: "click", args: [".read-story-link"] }]),
            text("There it is!"),
          ],
        },
      ],
    }),
    multiplayer,

    // ---- Tutorial Failed - System fallback ----
    createTutorial({
      system: true,
      title: "Tutorial Failed",
      scenes: [
        {
          directions: [
            text(
              "I must apologize. Something when wrong with the tutorial. I will work getting that fixed, in the meantime, check out one of our other tutorials",
            ),
            { type: "event", name: "close" },
          ],
        },
      ],
    }),

    // ---- April Fools ----
    createTutorial({
      system: true,
      title: i18n.global.t("tutorials.april-update"),
      tags: [{ id: "viewed", name: "viewed", permanent: true }],
      scenes: [
        {
          directions: [
            { type: "empty", effects: [{ tag: "viewed", op: "+" }] },
            // text("Debug: {{ viewed }}"),
            { type: "redirect", scene: 3, effects: [{ tag: "viewed", op: "==", value: 2 }] },
            { type: "redirect", scene: 4, effects: [{ tag: "viewed", op: "==", value: 3 }] },
            { type: "redirect", scene: 5, effects: [{ tag: "viewed", op: ">", value: 3 }] },
            { type: "redirect", scene: 2 },
          ],
        },
        {
          directions: [
            text(i18n.global.t("tutorials.hello-sorry-to-interrupt")),
            { type: "event", name: "close" },
          ],
        },
        {
          directions: [
            text(i18n.global.t("tutorials.aprilfools-hey-there-were-making-some-changes")),
            { type: "event", name: "close" },
          ],
        },
        {
          directions: [
            text(i18n.global.t("tutorials.aprilfools-corporate-has-decided")),
            { type: "event", name: "seasonal", args: 1 },
            { type: "event", name: "close" },
          ],
        },
        {
          directions: [
            { type: "event", name: "seasonal", args: 1 },
            { type: "group" },
            text(i18n.global.t("tutorials.aprilfools-just-a-little-reminder")),
            text(i18n.global.t("tutorials.aprilfools-hi-have-you-noticed-your-bill")),
            text(
              i18n.global.t(
                "tutorials.aprilfools-corporate-is-really-unhappy-with-my-sales-figures-n-sigh",
              ),
            ),
            text(i18n.global.t("tutorials.aprilfools-adding-up-the-totals")),
            // { type: 'event', name: 'automation', args: ['/malpractice/invoice'], effects: [{ tag: 'viewed', op: '>', value: 7 }]},
            { type: "event", name: "seasonal", args: 1 },
            { type: "event", name: "close" },
          ],
        },
      ],
    }),
    createTutorial({
      system: true,
      title: "April Finances",
      scenes: [
        {
          id: 1,
          directions: [
            text(i18n.global.t("tutorials.aprilfools-so-you-owe-us-quite-a-bit")),
            { type: "event", name: "close" },
          ],
        },
      ],
    }),
    createTutorial({
      system: true,
      title: "April Fools",
      scenes: [
        {
          id: 1,
          directions: [
            text(i18n.global.t("tutorials.aprilfools-you-broke-it")),
            text(i18n.global.t("tutorials.aprilfools-i-m-going-to-clean")),
            { type: "event", name: "seasonal", args: 2 },
            { type: "event", name: "close" },
          ],
        },
      ],
    }),
    // createTutorial(tagsAndActions),
  ];
}
