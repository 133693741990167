<style scoped>
.popover {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 8;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  pointer-events: none;
  max-width: 100vw;
}
.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--bg);
  opacity: 0.7;
  z-index: 1;
  pointer-events: auto;
}
.close {
  z-index: 3;
  padding: 5px;
  top: 0;
  left: calc(50% - 25px);
  width: 50px;
  position: absolute;
  justify-content: center;
  align-self: center;
  font-size: 2em;
}
.message-dialog {
  position: relative;
  top: 40px;
  z-index: 4;
  flex: 0 0 auto;
  padding: 10px;
  border-radius: 20px;

  /*
   * using width instead of height because
   * when a keyboard pops up, the height becomes too small
   */
  /* height: 100vw; */
  max-height: calc(100vh - 50px);
  max-width: 100vw;
  width: max-content;

  display: flex;
  overflow: visible;
  overflow-y: auto;
  touch-action: auto;
  pointer-events: auto;
  border: none !important;
  box-shadow: 0 3px 5px var(--bg);
}
.message-dialog.wide {
  width: calc(100vw - 10px);
}
#app .pointer {
  position: absolute;
  top: 0px;
  left: -15px;
  content: "";
  height: 0px;
  width: 0px;
  border: none;
  border: 15px solid transparent;
  border-bottom: 0px solid transparent;
  border-top: 15px solid var(--bg);
  z-index: 5;
  box-sizing: content-box;
  background: none;
}
#app .pointer.up {
  position: absolute;
  top: 1px;
  left: -15px;
  content: "";
  height: 0px;
  width: 0px;
  border: none;
  border: 15px solid transparent;
  border-top: 0px solid transparent;
  border-bottom: 15px solid var(--bg);
  z-index: 5;
  box-sizing: content-box;
  background: none;
}
#app .pointer.up.spark {
  border-bottom-color: var(--spark);
}
#app .pointer.up.hyper {
  border-bottom-color: var(--color3);
}
#app .pointer.up.accented {
  border-bottom-color: var(--color2);
}
#app .pointer.up.mellow {
  border-bottom-color: var(--color1);
}
#app .pointer.spark {
  border-top-color: var(--spark);
}
#app .pointer.hyper {
  border-top-color: var(--color3);
}
#app .pointer.accented {
  border-top-color: var(--color2);
}
#app .pointer.mellow {
  border-top-color: var(--color1);
}
.message-dialog.plain {
  background: var(--bg);
  color: var(--text);
}

.message-dialog a,
.message-dialog i {
  color: var(--bg);
}

.backdrop.pop-enter-active,
.backdrop.pop-leave-active {
  transition: opacity 400ms;
}
.backdrop.pop-enter-from,
.backdrop.pop-leave-to {
  opacity: 0;
}
</style>

<template>
  <teleport to="#app">
    <transition-group
      :id="`popover-${innerName}`"
      appear
      name="fade"
      tag="div"
      class="popover"
      v-show="isShown"
    >
      <div v-if="backdrop" key="backdrop" class="backdrop" @click.prevent="handleClose" />
      <div
        v-if="element || target"
        key="pointer"
        class="pointer"
        :class="{ up, [colorTheme]: true }"
        :style="{ transform: `translate(${pointer.x}px, ${coords.y + pointer.y}px)` }"
      />
      <div v-if="includeCloseButton" @click.prevent="hide()" class="close">&times;</div>
      <div
        v-bind="$attrs"
        key="message-dialog"
        class="message-dialog"
        :class="classes"
        ref="dialog"
        :style="{
          transform: `translate(${coords.x}px, ${coords.y - 37}px)`,
          // transformOrigin: `${coords.x}px ${coords.y}px`,
        }"
      >
        <slot>Hello!</slot>
      </div>
    </transition-group>
  </teleport>
</template>

<script>
export default {
  name: "Popover",
  inheritAttrs: false,
  props: {
    name: { type: String, default: null },
    value: { type: Boolean, default: false },
    target: { type: String, default: null },
    backdrop: { type: Boolean, default: true },
    colorTheme: { type: String, default: "mellow" },
    dialogClass: { type: [String, Object], default: "" },
    includeCloseButton: { type: Boolean, default: true },
  },
  emits: ["show"],
  data() {
    return {
      innerName: this.name,
      coords: { x: 0, y: 0 },
      pointer: { x: 0, y: 0 },
      element: null,
      up: false,
      isShown: this.value,
    };
  },
  computed: {
    classes() {
      const retval = {};
      if (this.colorTheme) {
        retval[this.colorTheme] = true;
      }
      if (typeof this.dialogClass === "string") {
        for (const klass of this.dialogClass.split(" ")) {
          retval[klass] = true;
        }
      } else {
        for (const key in this.dialogClass) {
          retval[key] = this.dialogClass[key];
        }
      }
      return retval;
    },
  },
  watch: {
    value() {
      this.isShown = this.value;
      this.reposition();
    },
  },
  mounted() {
    if (this.innerName) {
      if (!window.$popovers) {
        window.$popovers = {};
      }
      window.$popovers[this.innerName] = this;
    } else {
      this.innerName = Math.floor(Math.random() * 10000000000);
    }
    this.reposition();
    if (window.ResizeObserver) {
      this.resizer = new ResizeObserver(() => this.reposition());

      // Observe one or multiple elements
      if (this.$refs.dialog) {
        this.resizer.observe(this.$refs.dialog);
      }
    }
  },
  unmounted() {
    if (window.$popovers) {
      if (window.$popovers[this.innerName]) {
        delete window.$popovers[this.innerName];
      }
    }
    if (this.resizer) {
      this.resizer.disconnect();
    }
  },
  methods: {
    show(el, modifiers = {}) {
      this.element = modifiers.global ? null : el;
      this.$emit("update:value", true);
      this.$emit("show", true);
      this.isShown = true;
    },
    hide() {
      this.$emit("update:value", false);
      this.$emit("show", false);
      this.isShown = false;
    },
    async reposition() {
      await this.$nextTick();

      if (this.$refs.dialog) {
        const me = this.$refs.dialog.getBoundingClientRect();

        if (this.isShown && this.$el) {
          let target = this.element;
          if (this.target === null) {
            target = null;
          } else if (this.target) {
            target = document.querySelector(this.target);
          }
          if (target) {
            const box = target.getBoundingClientRect();
            const bottom = box.y + box.height;
            const center = box.x + box.width / 2;

            this.coords.x = Math.max(
              -5,
              Math.min(window.innerWidth - me.width + 5, center - me.width / 2)
            );
            this.pointer.x = center;
            if (bottom > window.innerHeight / 2) {
              // place above
              this.up = false;
              this.coords.y = box.y - 12 - me.height;
              this.pointer.y = me.height;
            } else {
              // place below
              this.up = true;
              this.coords.y = bottom + 12;
              this.pointer.y = -12;
            }
          } else {
            this.coords.x = window.innerWidth / 2 - me.width / 2;
            this.coords.y = 10; // window.innerHeight / 2 - me.height / 2;
          }
        }
      }
    },
    handleClose() {
      this.$emit("show", false);
      this.isShown = false;
    },
  },
};
</script>
