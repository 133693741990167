<template>
  <form class="vv-form" @submit.prevent="submit">
    <div
      v-for="{ type, name, label, help, attrs, ...extra } in props.schema"
      :key="name"
      class="vv-field"
      :class="{
        'vv-invalid': !!errors[name],
      }"
    >
      <label :for="name" class="vv-label">{{ label || name }}</label>
      <div class="vv-inner">
        <ErrorMessage :name="name" class="vv-messages" />
        <div class="vv-widget">
          <template v-if="fieldRegistry[type]">
            <component
              :is="fieldRegistry[type].comp"
              :id="name"
              :name="name"
              v-bind="{ ...fieldRegistry[type].attrs, ...attrs, ...extra }"
            />
          </template>
          <Field v-else :type="type" :id="name" :name="name" v-bind="attrs" />
        </div>
        <div v-if="help" class="vv-help">
          {{ help }}
        </div>
      </div>
    </div>
    <div class="vv-actions">
      <Button type="submit">Save</Button>
    </div>
  </form>
</template>

<script setup lang="ts">
import copy from "@virgodev/bazaar/functions/copy";
import { dottedGet, dottedSet } from "@virgodev/bazaar/functions/dotted_get";
import Button from "primevue/button";
import { ErrorMessage, Field, useForm } from "vee-validate";
import { computed, watch } from "vue";
import { registry, type VvField } from "./registry";

const props = withDefaults(
  defineProps<{
    modelValue: any;
    schema: VvField[];
    options?: { actions: boolean };
  }>(),
  { options: { actions: true } }
);
const emits = defineEmits(["submit", "update:values"]);

const validationSchema = computed(() => {
  const retval = {};
  for (const field of props.schema) {
    if (field.validation) {
      dottedSet(retval, field.name, field.validation);
    }
  }
  return retval;
});

const fieldRegistry = computed(() => {
  return registry;
});

const initialValues = computed(() => {
  console.log("props.modelValues", props.modelValue);
  const defaults = copy(props.modelValue || {});
  for (const field of props.schema) {
    const existing = dottedGet(defaults, field.name);
    if ([undefined, null].includes(existing) && field.default) {
      dottedSet(defaults, field.name, field.default);
    }
  }
  return {
    ...defaults,
  };
});

const { meta, values, errors, handleSubmit } = useForm({
  initialValues: initialValues.value,
  validationSchema: validationSchema.value,
});

const submit = handleSubmit(async (values) => {
  console.log("values", values);
  emits("submit", values);
});

watch(values, () => emits("update:values", values), { deep: true });
</script>

<style scoped>
.vv-form {
  display: grid;
  max-width: 600px;
  padding: 1em;
  gap: 1em;
  grid-template-columns: auto auto;
}

.vv-field {
  display: grid;
  grid-template-columns: subgrid;
  grid-column: span 2;
}
.vv-label {
  grid-column: 1/1;
  text-align: right;
  justify-content: flex-end;
  align-items: flex-start;
  padding-top: 0.75em;
}
.vv-inner {
  justify-self: stretch;
  align-self: center;
  text-align: left;
  grid-column: 2/2;
}
.vv-messages {
  margin-left: 0.35em;
  text-align: left;
  padding: calc(var(--spacing) * 2);
  color: var(--spark-text);
  background: var(--spark);
  border-radius: var(--softness);
}
.vv-invalid .vv-widget {
  /* border: 2px dashed red; */
}
.vv-help {
  padding-top: 0.5em;
  text-align: left;
  opacity: 0.75;
  font-size: 90%;
}
.vv-actions {
  grid-column: 2/2;
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
}
@media (max-width: 600px) {
  .vv-form {
    grid-template-columns: auto;
  }
  .vv-field,
  .vv-inner,
  .vv-actions {
    grid-template-columns: subgrid;
    grid-column: 1/1;
  }

  .vv-label {
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
  }
}
</style>
