import { palette, updatePreset } from "@primevue/themes";
import { defineStore } from "pinia";
import { Color } from "three";
import { computed, ref, unref, watch } from "vue";
import { lerpColors } from "../functions/colors";
import copy from "../functions/copy";
import { MorphosisPreset } from "../utils/morphosisPrimevueTheme";
import { createStore } from "./create_store";
import { useWebsocketStore } from "./websocket";

export interface Theme {
  id: string;
  darkColor: string;
  lightColor: string;
  accent: string;
  spark: string;
  dark: boolean;
  padding: number;
  spacing: number;
  softness: number;
  updated: string;
}

function inpx(x: number): string {
  let retval = `${x}`;
  if (!/px$/.test(retval)) {
    retval = `${retval}px`;
  }
  return retval;
}

export function updateColors(theme: Theme): { [key: string]: string } {
  // console.warn('-----------------');
  // console.log('accent', theme.accent);
  // console.log('dark', theme.dark);
  // console.log('spark', theme.spark);
  const color1 = new Color(theme.accent);
  const color2 = color1.clone();
  const color3 = color1.clone();

  const dark = "black"; // theme.darkColor;
  const light = "white"; //theme.lightColor;
  const text = new Color(theme.dark ? light : dark);
  let bg = new Color(!theme.dark ? light : dark);
  const spark = new Color(theme.spark);
  color1.lerp(bg, 0.6);
  color2.lerp(bg, 0.4);
  // color3.lerp(bg, 0.9);
  bg = bg.lerp(color3, 0.25);
  text.lerp(color3, 0.25);

  const rootVars: { [key: string]: string } = {};
  rootVars["--bg"] = `#${bg.getHexString()}`;
  rootVars["--padding"] = inpx(theme.padding);
  rootVars["--spacing"] = inpx(theme.spacing);
  rootVars["--softness"] = inpx(theme.softness);

  updatePreset(MorphosisPreset, {
    primitive: {
      borderRadius: {
        none: "0",
        xs: `${theme.softness * 0.3}px`,
        sm: `${theme.softness * 0.6}px`,
        md: `${theme.softness}px`,
        lg: `${theme.softness * 1.5}px`,
        xl: `${theme.softness * 2}px`,
      },
    },
    semantic: {
      surface: palette(`#${color1.getHexString()}`),
      primary: palette(`#${spark.getHexString()}`),
      colorScheme: {
        light: {
          surface: palette(`#${color1.getHexString()}`),
          primary: {
            color: "{primary.500}",
            contrastColor: "#ffffff",
            hoverColor: "{primary.600}",
            activeColor: "{primary.700}",
          },
        },
        dark: {
          surface: palette(`#${color1.getHexString()}`),
          primary: {
            color: "{primary.500}",
            contrastColor: "#ffffff",
            hoverColor: "{primary.600}",
            activeColor: "{primary.700}",
          },
        },
      },
    },
  });

  return {
    ...rootVars,
    ...lerpColors("color1", color1),
    ...lerpColors("color2", color2),
    ...lerpColors("color3", color3),
    ...lerpColors("accent", color1),
    ...lerpColors("spark", spark),
  };
}

const useThemesStore = createStore<Theme>("themes", { endpoint: "theme" });

export const useThemeStore = defineStore("theme", () => {
  const themes = useThemesStore();
  const socket = useWebsocketStore();

  const defaultTheme: Theme = {
    id: "app",
    darkColor: "black",
    lightColor: "white",
    accent: "#6d07cc",
    spark: "#1e71cf",
    dark: true,
    padding: 5,
    spacing: 5,
    softness: 5,
    updated: new Date().toISOString(),
  };
  const vars = ref<Record<string, string>>({});
  const saved = computed(themes.get("app"));
  const current = ref<Theme>(copy(themes.list.find((t) => t.id === "app") || defaultTheme));
  const offline = ref(true);

  watch(
    () => current.value.updated,
    async () => {
      // console.log("theme changed", themes.list);
      saved.value = current.value;
      vars.value = updateColors(current.value);
    }
  );

  watch(vars, () => {
    updateStyle();
  });

  async function setup() {
    offline.value = false;
    await themes.ready();

    const temp: Theme = themes.list.find((t) => t.id === "app");
    if (temp) {
      current.value = unref(temp);
    } else if (themes.list.length === 0) {
      await themes.create(defaultTheme);
    }
    vars.value = updateColors(current.value);
    await themes.sync();

    if (!socket.socket) {
      console.warn(
        "socket is not initialized! Rewrite to ensure socket is ready before using themes"
      );
    } else {
      await socket.socket.ready;
      await socket.socket.addEventListener("db_update", (packet) => {
        if (packet.model === "theme") {
          const content = JSON.parse(packet.content);
          current.value = content;
        }
      });
      await socket.send({ event: "db_listen", data: { model: "theme" } });
    }
  }

  async function reset() {
    current.value = {
      id: "app",
      ...defaultTheme,
      updated: new Date().toISOString(),
    };
    vars.value = updateColors(current.value);
  }

  function updateStyle() {
    let styleTag: HTMLStyleElement = document.querySelector("#root-vars");
    if (!styleTag) {
      styleTag = document.createElement("style");
      document.head.appendChild(styleTag);
      styleTag.id = "root-vars";
    }
    let sheet = "#app {";
    for (const key in vars.value) {
      const value = vars.value[key];
      sheet += `  ${key}: ${value};`;
    }
    sheet += "}";
    styleTag.innerHTML = sheet;
  }

  function update() {
    vars.value = updateColors(current.value);
  }

  function push() {
    current.value.updated = new Date().toISOString();
    themes.push(current.value);
  }

  return { offline, current, vars, reset, setup, update, updateStyle, push };
});
