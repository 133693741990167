<template>
  <div style="display: none">{{ which }}</div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from "vue";

const which = ref("");
const listeners = {};
const keytypes = ["keypress", "keydown", "keyup"];
const emits = defineEmits(["keypress", "keydown", "keyup"]);

onMounted(() => {
  for (const key of keytypes) {
    const listener = (e) => {
      which.value = e.which;
      emits(key, e);
    };
    document.addEventListener(key, listener);
    listeners[key] = listener;
  }
});

onUnmounted(() => {
  for (const key of keytypes) {
    document.removeEventListener(key, listeners[key]);
  }
});
</script>
