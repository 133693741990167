<template>
  <popover
    id="command-search"
    @update:value="$emit('update:value', $event)"
    @show="$emit('show', $event)"
    color-theme="mellow"
    :value="true"
  >
    <!-- @keydown.capture="handleKeypress" -->
    <div v-if="status">
      <div class="result action">
        <div class="row">
          <div class="icon"><i class="fa fa-plus-circle fa-spin" /></div>
          {{ $t("command_search_popup.executing-command") }}
        </div>
      </div>
    </div>
    <div v-else class="left relative">
      <div class="search-form padded fixed row">
        <input
          class="filter-input"
          :placeholder="$t('command_search_popup.search-commands')"
          ref="typing"
          v-model="filter"
          @keydown="handleKeypress"
        />
        <a
          @click.prevent="$emit('show', false)"
          @keypress.prevent="$emit('show', false)"
          class="close-button"
        >
          &times;
        </a>
      </div>

      <!-- <div class="result action">
        <div class="row">
          <div class="icon"><i class="fa fa-plus-circle" /></div>
          <a
            class="create-link title stretch"
            @click.prevent="handleCreate"
            @keypress.prevent="handleCreate"
            tabindex="0"
          >
            {{ filter ? $t('scene_search_popup.create-new-scene-with-title', {title: filter}) : $t('scene_search_popup.create-new-scene') }}
          </a>
        </div>
      </div> -->
      <div class="search left top" v-if="results">
        <div class="result" v-for="result of results.slice(0, max)" :key="result.item.id">
          <div class="row">
            <a
              class="title stretch"
              @click.prevent="
                $emit('show', false);
                $emit('update:value', result.item);
              "
              @keypress.prevent="
                $emit('show', false);
                $emit('update:value', result.item);
              "
              tabindex="0"
            >
              {{ result.item.label }}
            </a>
            <div>{{ result.item.group }}</div>
            <div>{{ result.item.beta }}</div>
          </div>
        </div>
      </div>
    </div>
  </popover>
</template>

<script>
import Fuse from "fuse.js";
import directions from "./directions/index";

export default {
  name: "CommandSearchPopup",
  props: {
    value: { type: [Number, String] },
    story: Object,
    scene: Object,
    direction: Object,
  },
  data() {
    return {
      status: null,
      filter: null,
      results: null,
      max: 15,
    };
  },
  computed: {
    commands() {
      let retval = directions.map((d) => {
        return {
          id: `direction-create-${d.name}`,
          label: this.$t("command_search_popup.create-direction", {
            direction_type: this.$t(`directionType.${d.name}`),
          }),
          cmd: "direction-create",
          params: d.name,
        };
      });
      retval.push({ id: "export", label: this.$t("command_search_popup.export-story-json") });
      retval.push({
        id: "scene-duplicate",
        label: this.$t("command_search_popup.duplicate-scene"),
      });
      retval.push({
        id: "fill-debug",
        label: this.$t("command_search_popup.debug-fill-this-scene-with-directions"),
      });

      if (this.direction) {
        retval = retval.concat([
          { id: "set-effect", label: this.$t("command_search_popup.set-tag"), keywords: "tag" },
          {
            id: "set-requirement",
            label: this.$t("command_search_popup.check-tag"),
            keywords: "tag",
          },
          {
            id: "formula-effect",
            label: this.$t("command_search_popup.create-formula-effect"),
            keywords: this.$t("command_search_popup.keyword-tag"),
          },
          {
            id: "formula-test",
            label: this.$t("command_search_popup.create-formula-test"),
            keywords: this.$t("command_search_popup.keyword-tag"),
          },
          {
            id: "direction-duplicate-na-text",
            cmd: "direction-duplicate",
            label: this.$t("command_search_popup.duplicate-except-text"),
            params: { notext: true },
          },
          {
            id: "direction-duplicate",
            label: this.$t("command_search_popup.duplicate"),
            params: {},
          },
          {
            id: "direction-delete",
            label: this.$t("command_search_popup.delete-direction"),
            keywords: this.$t("command_search_popup.keywords-delete-remove-erase"),
          },
          {
            id: "roll-d6",
            cmd: "roll-dice",
            label: this.$t("command_search_popup.roll-a-dice-dice", { dice: "D6" }),
            params: 6,
            keywords: this.$t("command_search_popup.dice"),
          },
          {
            id: "roll-d10",
            cmd: "roll-dice",
            label: this.$t("command_search_popup.roll-a-dice-dice", { dice: "D10" }),
            params: 10,
            keywords: this.$t("command_search_popup.dice"),
          },
          {
            id: "move-up",
            cmd: "move",
            label: this.$t("command_search_popup.move-direction-up"),
            params: -1,
          },
          {
            id: "move-down",
            cmd: "move",
            label: this.$t("command_search_popup.move-direction-down"),
            params: 1,
          },
          { id: "previous", label: this.$t("command_search_popup.go-to-previous-scene") },
          {
            id: "split",
            label: this.$t("command_search_popup.split-this-and-below-into-a-new-scene"),
          },
        ]);
        if (this.direction.type === "text") {
          retval.push({ id: "set-character", label: this.$t("character_modal.change-character") });
        }

        // add character based text creation
        for (const character of this.story.characters) {
          for (const emote of character.emotes) {
            retval.push({
              id: `create-text-for-${character.id}-${emote.id}`,
              label: `Create text for "${character.name}" | "${emote.name}"`,
              params: { character: character.id, state: emote.id },
              cmd: "create-text-for",
            });
          }
        }
      }
      return retval;
    },
    // linked() { return this.scenes.find(s => s.id === this.value); },
    // linkedTitle() {
    //   if (this.linked) {
    //     return this.linked.title || this.sceneGetText(this.linked);
    //   }
    // },
  },
  watch: {
    async value() {
      this.filter = null;
      this.status = null;
    },
    filter() {
      this.max = 15;
      this.loadCommands();
    },
  },
  async mounted() {
    this.loadFuse();
    await this.$nextTick();
    const input = this.$refs.typing;
    if (input) {
      input.select();
    }
  },
  methods: {
    async handleCreate() {
      const title = this.filter;
      this.status = this.$t("command_search_popup.creating");

      // create new scene
      const scene = await this.story.createScene({ title });
      this.$emit("show", false);
      this.$emit("update:value", scene.id);
      await this.$nextTick();

      // go to new scene
      this.$router.push({
        name: "scene",
        params: { story: this.story.id, id: scene.id },
      });
    },
    loadFuse() {
      let commands = this.commands.slice();
      if (!this.direction) {
        commands = commands.filter((c) => !c.requireDirection);
      }
      this.fuse = new Fuse(commands, {
        keys: ["label", "keywords"],
        includeMatches: true,
        findAllMatches: true,
      });
      this.loadCommands();
    },
    loadCommands() {
      if (this.filter) {
        this.results = this.fuse.search(this.filter);
      } else {
        this.results = this.fuse._docs
          .map((c) => {
            return { item: c };
          })
          .reverse();
      }
    },
    handleKeypress(e) {
      if (e.key === "Escape") {
        this.$emit("show", false);
      } else if (e.key === "Enter") {
        if (this.results.length > 0) {
          e.preventDefault();
          this.$emit("show", false);
          this.$emit("update:value", this.results[0].item);
        }
      }
    },
  },
};
</script>

<style scoped>
a {
  padding: 0;
}
.search {
  max-width: 100%;
}
.result {
  margin: 0;
}
.result a {
  padding: 8px;
  font-weight: bold;
}
.top {
  align-self: flex-start;
}
.label {
  background: var(--color2);
  color: var(--color2_text);
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
}
.title,
.content {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.icon {
  flex: 0 0 auto;
  padding: 5px;
  margin-right: 9px;
  font-size: 21px;
}
.action {
  padding: 5px;
  opacity: 0.8;
}
.search-form {
  align-items: center;
}
#app .search-form .close-button {
  display: inline-block;
  flex: 0 0 auto;
  margin: 0;
  font-size: 25px;
  height: 30px;
  width: 30px;
  padding: 0;
  place-self: flex-end;
  align-self: flex-start;
  text-align: right;
  margin: 0;
}
input {
  margin: 0;
}
</style>
