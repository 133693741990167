import { diff } from "jsondiffpatch";
import localforage from "localforage";
import { nanoid } from "nanoid";
import { nextTick, ref, Ref, watch } from "vue";
import copy from "../functions/copy";

export interface Model {
  id: string;
  updated: string;
}

export function createStorageAdapter<T extends Model>(
  name: string,
  options: { prefix?: string; migrate?: (a: Partial<T>) => T } = {}
) {
  const prefix = options.prefix ? options.prefix + ":" : "";
  const lsUpdatedKey = `${prefix}${name}:lastUpdate`;
  const store = localforage.createInstance({ name: `${prefix}${name}` });
  const list: Ref<T[]> = ref([]);
  const list2: Ref<T[]> = ref([]);
  const updated = ref(new Date(localStorage[lsUpdatedKey] || 0));
  const migrate = options.migrate || ((a: Partial<T>) => a as T);
  const patching = ref(false);
  const missed = ref(0);

  watch(updated, () => {
    localStorage[lsUpdatedKey] = updated.value.toISOString();
  });

  watch(
    list,
    async () => {
      if (!patching.value) {
        if (await patch()) {
          updated.value = new Date();
        }
      } else {
        missed.value += 1;
      }
    },
    { deep: true }
  );

  async function patch() {
    patching.value = true;
    let listChanged = false;
    while (true) {
      for (let object of list.value) {
        if (!object) {
          console.log("list.value has bad value", list.value);
        }
        if (!object.id) {
          object.id = nanoid();
          object = migrate(object);
        }
        const index = list2.value.findIndex((i) => i.id === object.id);
        let changed = false;
        if (index > -1) {
          const patch = diff(object, list2.value[index]);
          changed = !!patch;
        } else {
          changed = true;
        }

        // save changed
        if (changed) {
          listChanged = true;
          await store.setItem(object.id, copy(object));
        }
      }

      // delete removed
      for (const object of list2.value) {
        const index = list.value.findIndex((i) => i.id === object.id);
        if (index === -1) {
          await store.removeItem(object.id);
          listChanged = true;
        }
      }
      list2.value = copy(list.value);
      if (missed.value) {
        missed.value = 0;
      } else {
        break;
      }
    }
    patching.value = false;
    return listChanged;
  }

  async function copyFromStorage() {
    patching.value = true;
    await store.iterate((row: T) => {
      const match = list.value.find((o) => o.id === row.id);
      if (match) {
        if (match.updated < row.updated) {
          for (const key in row) {
            match[key] = row[key];
          }
        }
      } else {
        // console.log("adding from storage", name, row);
        list.value.push(migrate(row));
      }
    });
    list2.value = copy(list.value);
    patching.value = false;
  }

  // load list immediately
  async function setup() {
    window.addEventListener("storage", async (e) => {
      console.log("storage event", name, e);
      if (e.key === lsUpdatedKey) {
        const current = new Date(localStorage[lsUpdatedKey]);
        if (current > updated.value) {
          await copyFromStorage();
          updated.value = current;
        }
      }
    });
    await copyFromStorage();
  }

  const isSetupPromise = setup();
  // console.log("setting up storage", name);
  async function ready() {
    await isSetupPromise;
    await nextTick();
    // console.log("storage done", name);
  }

  return { store, list, updated, ready };
}
