import { Directory, Encoding, Filesystem } from "@capacitor/filesystem";
import { nextTick } from "vue";

export interface StorageEngine<T> {
  setItem(name: string, value: T): Promise<void>;
  getItem(name: string, useAsDefault: T): Promise<T>;
  removeItem(name: string): Promise<void>;
  clear(): Promise<void>;
}

export interface FileStorageOptions {
  name: string;
  encoding?: Encoding;
  ext?: string;
  directory?: Directory;
}

export class FileStorage<T extends any> implements StorageEngine<T> {
  opts: FileStorageOptions;
  isReady: Promise<void>;
  path: string;

  constructor(opts: FileStorageOptions) {
    this.opts = opts;
    if (!this.opts.ext) {
      this.opts.ext = ".json";
    }
    if (this.opts.encoding === undefined) {
      this.opts.encoding = Encoding.UTF8;
    }
    if (!this.opts.directory) {
      this.opts.directory = Directory.Data;
    }

    this.path = this.opts.name;

    this.isReady = (async () => {
      await Filesystem.requestPermissions();
      const path = this.path;
      console.log("is ready?", path, this.opts.directory);
      let folder = null;
      try {
        folder = await Filesystem.stat({
          path,
          directory: this.opts.directory,
        });
      } catch (err) {}
      try {
        console.log("folder exists?", folder);
        if (!folder) {
          await Filesystem.mkdir({
            path,
            directory: this.opts.directory,
            recursive: true,
          });
        }
      } catch (err) {
        console.warn("Failed to create folder", err);
        throw err;
      }
    })();
  }

  getFilePath(name: string): string {
    return `${this.opts.name}/${name}${this.opts.ext}`;
  }

  async getItem(name: string, useAsDefault?: T): Promise<T> {
    await this.isReady;
    const path = this.getFilePath(name);
    console.warn("file storage: get file", path);
    let entry = undefined;
    try {
      entry = await Filesystem.readFile({
        path,
        directory: this.opts.directory,
        encoding: this.opts.encoding,
      });
    } catch (ex) {}
    if (entry) {
      if (typeof entry.data === "string") {
        return JSON.parse(entry.data);
      } else {
        return entry.data as T;
      }
    }
    return useAsDefault;
  }

  async setItem(name: string, value: T): Promise<void> {
    await this.isReady;
    console.log("setItem", this.opts.directory);
    await nextTick();
    await Filesystem.writeFile({
      path: this.getFilePath(name),
      directory: this.opts.directory,
      encoding: this.opts.encoding,
      data: JSON.stringify(value),
    });
  }

  async removeItem(name: string): Promise<void> {
    await this.isReady;
    console.log("removeItem", this.opts.directory);
    await Filesystem.deleteFile({
      path: this.getFilePath(name),
      directory: this.opts.directory,
    });
  }

  async clear() {
    await this.isReady;
    console.log("clear", this.opts.directory);
    await Filesystem.deleteFile({
      path: this.opts.name,
      directory: this.opts.directory,
    });
  }

  async iterate(callback: (T) => boolean | undefined): Promise<void> {
    await this.isReady;
    const read = await Filesystem.readdir({
      path: this.opts.name,
      directory: this.opts.directory,
    });
    for (const file of read.files) {
      if (file.type === "file") {
        console.log("iterate", this.opts.directory, file);
        const data = await Filesystem.readFile({
          path: file.uri,
          // directory: this.opts.directory,
          encoding: this.opts.encoding,
        });
        let contents: T | undefined = undefined;
        if (typeof data.data === "string") {
          contents = JSON.parse(data.data);
        } else {
          contents = data.data as T;
        }
        if (await callback(contents)) {
          return;
        }
      }
    }
  }
}
