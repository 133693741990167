<template>
  <div v-if="story && status" class="tutorial-overview" :class="{ show: status === 'show' }">
    <div class="backdrop" v-if="status === 'show'" />
    <div class="story" v-show="status === 'show'">
      <story-layout ref="story" :story="story" @event="handleEvent">
        <template v-slot="{ restartCmd }">
          <div class="flex-row flex-right">
            <button @click="restartCmd"><i class="fa fa-repeat" /></button>
            <button @click="handleCloseStory"><i class="fa fa-close" /></button>
          </div>
        </template>
      </story-layout>
    </div>
    <div class="tutorial-buttons flex-row flex-align-center">
      <a v-if="status === 'show'" @click.prevent="handleCloseStory" class="tutorial-close">
        <!-- <vi name="chevronleft" /> -->
        <!-- <span>&times; {{ story.title }}</span> -->
        <i class="fa fa-close" /> {{ $t("tutorial_overview.close") }}
      </a>
      <div v-html="status === 'show' ? '&mdash;&nbsp;' : ''" />
      {{ story.title }}
    </div>
  </div>
</template>

<script>
import { useTutorialStore } from "@/stores/tutorials";
import { mapStores } from "pinia";
import Story from "../models/story";
import StoryLayout from "./reader/layout.vue";

export default {
  name: "TutorialOverview",
  components: {
    StoryLayout,
  },
  props: {
    storyId: { type: String },
  },
  data() {
    return {
      status: "show",
      story: null,
    };
  },
  computed: {
    ...mapStores(useTutorialStore),
  },
  watch: {
    storyId() {
      this.handleLoadStory();
      this.status = "show";
    },
  },
  async mounted() {
    if (this.storyId) {
      this.handleLoadStory();
    }
  },
  methods: {
    handleCloseStory() {
      this.$emit("update:storyId", null);
    },
    async handleLoadStory() {
      this.story = null;
      if (this.storyId) {
        const retval = this.tutorialsStore.stories.find((t) => t.id === this.storyId);
        if (retval) {
          // is there a test story
          const testStory = await Story.load("test");

          // increment tutorial count
          const tutorialCount = this.fetch("tutorial-count", 0) + 1;
          this.put("tutorial-count", tutorialCount);

          let tags = {
            hasTestStory: !!testStory,
            tutorialCount,
          };
          const prev = this.fetch(`story_${retval.id}`);
          if (prev && prev.tags) {
            for (const key of Object.keys(prev.tags)) {
              if (Object.keys(tags).indexOf(key) === -1) {
                tags[key] = prev.tags[key];
              }
            }
          }

          this.put(`story_${retval.id}`, {
            tags,
            choices: [],
          });
          this.story = retval;
        }
      }
    },
    handleClick(event) {
      this.status = event;
    },
    async handleEvent(event) {
      try {
        if (event.name === "seasonal") {
          console.log("event", event.name, event.args);
          this.$root.eventDetails = event.args;
        } else if (event.name === "close") {
          this.$emit("update:storyId", null);
          // this.status = 'hide';
        } else if (event.name === "hide") {
          this.status = "hide";
        } else if (event.name === "automate") {
          this.$root.automated = true;
          await this.$nextTick();

          const cursor = this.$root.$refs.cursor;
          const result = await cursor.automate(event.content);
          if (result.error) {
            throw new Error(result.error);
          }
        }
        this.$refs.story.handleEvent(event.name);
      } catch (ex) {
        await this.loadTutorialFailed();
      } finally {
        this.$root.automated = false;
        await this.$nextTick();
      }
    },
    async loadTutorialFailed() {
      this.$emit("update:storyId", "tutorial-failed");
      await this.$nextTick();
      this.handleLoadStory();
    },
  },
};
</script>

<style scoped>
.tutorial-overview {
  position: fixed;
  bottom: 0;
  left: 0;
  width: max-content;
  white-space: nowrap;
  z-index: 10;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  background: rgba(0, 0, 0, 0.8);
  border-radius: calc(var(--spacing) * 2);
}
.tutorial-overview.show {
  width: auto;
  background: transparent;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
}
.tutorial-overview h2 {
  padding: var(--padding);
  font-size: 15px;
  margin: var(--spacing);
  margin-bottom: calc(-20px - var(--spacing));
}
.tutorial-overview .backdrop {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
}
.tutorial-overview .story {
  position: relative;
  background: rgba(0, 0, 0, 0.8);
  margin-left: -0px;
  margin-bottom: -0px;
  padding: 0;
  border-radius: calc(var(--spacing) * 2);
  z-index: 2;
  max-width: 600px;
}
.tutorial-overview.show .tutorial-buttons {
  position: absolute;
  z-index: 2;
  margin-top: -45px;
  padding: 0 5px 5px 5px;
  bottom: 0;
}
#app .tutorial-buttons {
  background: none;
  border: none;
  justify-content: flex-start;
  max-width: max-content;
  padding: 15px;
}
.tutorial-buttons a {
  padding: 5px;
}
.tutorial-buttons span {
  display: block;
  padding: var(--spacing);
}
.tutorial-overview .top-buttons {
  text-align: right;
  justify-content: flex-end;
  margin-bottom: -55px;
  position: relative;
  z-index: 2;
}
</style>
