import MgPlugin from "@morphosis/base/install";
import ApiMixin from "@morphosis/base/utils/api_mixin";
import { createHead, VueHeadMixin } from "@unhead/vue";
import BitStream from "lamejs/src/js/BitStream";
import Lame from "lamejs/src/js/Lame";
import MPEGMode from "lamejs/src/js/MPEGMode";
import "primeicons/primeicons.css";
import PrimeVue from "primevue/config";
import { createApp } from "vue";
import VueTouch from "vue3-touch-events";
import "./assets/style.css";

import { registerVvType } from "@morphosis/base/components/forms/registry";

import { i18n } from "@/i18n";
import { MorphosisPreset } from "@morphosis/base/utils/morphosisPrimevueTheme";
import * as Sentry from "@sentry/vue";
import { Buffer } from "buffer";
import { createPinia } from "pinia";
import App from "./App.vue";
import VvAnimationSelect from "./components/editor/admin/inputs/VvAnimationSelect.vue";
import VvMediaUploadInput from "./components/editor/admin/inputs/VvMediaUploadInput.vue";
import VvStyleSelect from "./components/editor/admin/inputs/VvStyleSelect.vue";
import global from "./global";
import router from "./router";

import "./polyfills";

// working around this: https://github.com/zhuker/lamejs/issues/86
window.MPEGMode = MPEGMode;
window.Lame = Lame;
window.BitStream = BitStream;

globalThis.Buffer = Buffer;
globalThis.process = {};

const app = createApp(App);
window.app = app;

app.use(PrimeVue, {
  theme: {
    preset: MorphosisPreset,
    options: {
      darkModeSelector: ".is-dark-active",
    },
  },
});
app.use(i18n);
// if (location.port === "8090") {
//   app.use(new WebsocketPlugin(), "http://192.168.4.100:9000/ws/", {
//     connect: false,
//     fallback: "http://192.168.4.100:9000/api/",
//     heartBeatTimeout: 5 * 1000,
//   });
// } else {
//   app.use(new WebsocketPlugin(), "https://tsunami.darkchocolate.dev/ws/", {
//     connect: false,
//     fallback: "https://tsunami.darkchocolate.dev/api/",
//     heartBeatTimeout: 5 * 1000,
//   });
// }

app.mixin(ApiMixin);
app.mixin(global);
app.use(createPinia());
app.use(createHead());
app.mixin(VueHeadMixin);
app.use(router);
app.use(MgPlugin);
app.use(VueTouch);

if (location.host !== "localhost:8080") {
  Sentry.init({
    app,
    dsn: "https://b4283448ae26672c4af96366d02e6c7b@o4505070834548736.ingest.us.sentry.io/4508226674098176",
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({ maskAllText: false, blockAllMedia: false }),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/tsunami.darkchocolate\.dev\//],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

registerVvType("media", VvMediaUploadInput);
registerVvType("animation", VvAnimationSelect);
registerVvType("style", VvStyleSelect);

// should be last
// sentry requires this to be after
app.mount("#app");
